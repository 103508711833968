import { Input, Modal, Typography, Steps, Button } from 'antd'
import { useState } from 'react'
import moment, { Moment } from 'moment'
import { Box } from '@mui/system'
import { styled } from '@mui/styles'
import { useHistory } from 'react-router-dom'
import {
    SalesOrder,
    SopBuiltItemOrderline,
    SopStockItemOrderline,
} from '../../../../../models/models'
import Table, { ColumnsType } from 'antd/lib/table'
import { toastFailure, toastSuccess } from '../../../../../util/toast'
import { CreateCallOffBodyDTO, createCallOffRequest } from '../../../../../api/call-off'
import InformationButton from '../../../../ui/buttons/InfromationButton'
import { isPastOrWeekend, isPast } from '../../../../../util/util'
import DatePicker from '../../../../ui/calendar/DatePicker'

const ItemContainer = styled(Box)({ display: 'flex', flexDirection: 'column', padding: '20px' })

const AddedItemsContainer = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    padding: '20px',
})

interface AddedBuiltItemSop extends SopBuiltItemOrderline {
    addedAmount: number
}

interface AddedStockItemSop extends SopStockItemOrderline {
    addedAmount: number
}

interface CreateCallOffProps {
    salesOrder: SalesOrder
    onClose: () => void
    onCreate?: () => void
}

interface AmountInputState {
    //key is the id of the product sop, the value is the quantity
    [key: number]: string
}

const isAddedBuiltItemSop = (
    addedItem: AddedBuiltItemSop | AddedStockItemSop
): addedItem is AddedBuiltItemSop => {
    return (addedItem as AddedBuiltItemSop).builtItemId !== undefined
}

const isAddedStockItemSop = (
    addedItem: AddedBuiltItemSop | AddedStockItemSop
): addedItem is AddedStockItemSop => {
    return (addedItem as AddedBuiltItemSop).builtItemId === undefined
}
const Label = (props: { text: string; isInvalid?: boolean }) => {
    const { text } = props
    return (
        <Box style={{ width: '270px' }}>
            <Typography style={{ color: '#262626', opacity: '45%' }}>{text}</Typography>
        </Box>
    )
}

const InputContainer = styled(Box)({
    display: 'flex',
    flexDirection: 'row',
    marginBottom: '15px',
})

interface CreateCallOffProps {
    salesOrder: SalesOrder
    onClose: () => void
    onCreate?: () => void
}

type FormDateKeys = 'customerDispatchDate' | 'dateToBeCompletedBy' | 'deliveryDate' | 'goodsInDate'

type FormStringKeys = 'comment' | 'title'

interface FormState {
    customerDispatchDate: Moment | null
    dateToBeCompletedBy: Moment | null
    deliveryDate: Moment | null
    goodsInDate: Moment | null
    comment: string
    title: string
}

const initialState = {
    customerDispatchDate: null,
    dateToBeCompletedBy: null,
    goodsInDate: null,
    deliveryDate: null,
    comment: '',
    title: '',
}

enum ModalState {
    Products = 'PRODUCTS',
    Details = 'DETAILS',
}

export default function CreateCallOff({ onClose, salesOrder }: CreateCallOffProps) {
    const [formState, setFormState] = useState<FormState>(initialState)
    const [modalState, setModalState] = useState<ModalState>(ModalState.Products)

    const history = useHistory()
    const { builtItemOrderlines, stockItemOrderlines } = salesOrder

    const [builtItemAmountInputs, setBuiltItemAmountInputs] = useState<AmountInputState>(
        builtItemOrderlines.reduce<AmountInputState>((acc: AmountInputState, cur) => {
            acc[cur.id] = ''
            return acc
        }, {})
    )
    const [stockItemAmountInputs, setStockItemAmountInputs] = useState<AmountInputState>(
        stockItemOrderlines.reduce<AmountInputState>((acc: AmountInputState, cur) => {
            acc[cur.id] = ''
            return acc
        }, {})
    )
    const [addedBuiltItems, setAddedBuiltItems] = useState<AddedBuiltItemSop[]>([])
    const [addedStockItems, setAddedStockItems] = useState<AddedStockItemSop[]>([])

    const getBuiltItemSopUsed = (sopBuiltItemOrderline: SopBuiltItemOrderline): number => {
        return sopBuiltItemOrderline.despatchReceiptQuantity + sopBuiltItemOrderline.reserved
    }

    const getStockItemSopUsed = (sopStockItemOrderline: SopStockItemOrderline): number => {
        return sopStockItemOrderline.despatchReceiptQuantity + sopStockItemOrderline.reserved
    }

    const createCallOff = async () => {
        if (
            !formState.customerDispatchDate ||
            !formState.deliveryDate ||
            !formState.dateToBeCompletedBy
        ) {
            toastFailure('Missing dates or d')
            return
        }

        const body: CreateCallOffBodyDTO = {
            comment: formState.comment,
            title: formState.title,
            customerDispatchDate: formState.customerDispatchDate.toDate(),
            dateToBeCompletedBy: formState.dateToBeCompletedBy.toDate(),
            deliveryDate: formState.deliveryDate?.toDate(),
            dispatchComment: formState.comment,
            addedBuiltItems: addedBuiltItems.map((addedBuiltItem) => {
                return {
                    builtItemSopId: addedBuiltItem.id,
                    amount: addedBuiltItem.addedAmount,
                }
            }),

            addedStockItems: addedStockItems.map((addedStockItem) => {
                return {
                    stockItemSopId: addedStockItem.id,
                    amount: addedStockItem.addedAmount,
                }
            }),

            salesOrderId: salesOrder.id,
            goodsInDate: formState.goodsInDate?.toDate(),
        }

        const response = await createCallOffRequest(body)
        if (response.successful) {
            onClose()
            toastSuccess('Created call off')
            history.push(`/dashboard/project-master/${salesOrder.projectId}/call-offs`)
        } else {
            toastFailure(response.message)
        }
    }

    const itemInputHandler = (input: string, remainding: number | undefined) => {
        if (remainding === undefined) {
            return ''
        }
        if (parseInt(input, 10) < 0) {
            return '0'
        }
        if (input.length > 1 && input[0] === '0') {
            input = input.slice(1, input.length)
        }
        if (parseInt(input, 10) > remainding) {
            input = remainding.toString()
        }
        return input
    }

    const pushAddedBuiltItemSop = (builtItem: SopBuiltItemOrderline, amount: number) => {
        setAddedBuiltItems([
            ...addedBuiltItems.filter(
                (addedBuiltItem) => addedBuiltItem.builtItemId !== builtItem.builtItemId
            ),
            { ...builtItem, addedAmount: amount },
        ])
    }

    const removeAddedBuiltItemSop = (addedBuiltItemId: number) => {
        setAddedBuiltItems(addedBuiltItems.filter((i) => i.id !== addedBuiltItemId))
    }

    const pushAddedProjectStockItem = (stockItem: SopStockItemOrderline, amount: number) => {
        setAddedStockItems([
            ...addedStockItems.filter(
                (addedStockItem) => addedStockItem.productId !== stockItem.productId
            ),
            { ...stockItem, addedAmount: amount },
        ])
    }

    const removeAddedProjectStockItem = (addedStockItemId: number) => {
        setAddedStockItems(addedStockItems.filter((i) => i.id !== addedStockItemId))
    }

    const builtItemHasBeenAdded = (id: number) =>
        addedBuiltItems.find((bi) => bi.id === id) !== undefined

    const stockItemHasBeenAdded = (id: number) =>
        addedStockItems.find((bi) => bi.id === id) !== undefined

    const updateStockProductInput = (productId: number, value: string) => {
        const inputState = { ...stockItemAmountInputs }
        inputState[productId] = value
        setStockItemAmountInputs(inputState)
    }

    const updateBuiltProductInput = (productId: number, value: string) => {
        const inputState = { ...builtItemAmountInputs }
        inputState[productId] = value
        setBuiltItemAmountInputs(inputState)
    }

    const updateDateFieldForm = (key: FormDateKeys, value: Moment | null) => {
        const state = { ...formState }
        state[key] = value
        setFormState(state)
    }

    const updateStringFieldForm = (key: FormStringKeys, value: string) => {
        const state = { ...formState }
        state[key] = value
        setFormState(state)
    }

    const shouldDisableProductsContinueButton = () => {
        return !(addedBuiltItems.length + addedStockItems.length > 0)
    }

    const shouldDisableDetailsContinueButton = () => {
        return !(
            !shouldDisableProductsContinueButton() &&
            formState.customerDispatchDate &&
            formState.deliveryDate &&
            formState.dateToBeCompletedBy &&
            formState.deliveryDate.isSameOrAfter(formState.customerDispatchDate, 'day') &&
            formState.customerDispatchDate.isSameOrAfter(formState.dateToBeCompletedBy, 'day')
        )
    }

    const ProductsFooter = () => {
        return (
            <Box>
                <Button onClick={onClose}>Cancel</Button>
                <Button
                    disabled={shouldDisableProductsContinueButton()}
                    onClick={() => setModalState(ModalState.Details)}
                >
                    Continue
                </Button>
            </Box>
        )
    }

    const DetailsFooter = () => {
        return (
            <Box>
                <Button onClick={onClose}>Cancel</Button>

                <Button disabled={shouldDisableDetailsContinueButton()} onClick={createCallOff}>
                    Create call off
                </Button>
            </Box>
        )
    }

    const currentStep = () => {
        switch (modalState) {
            case ModalState.Products:
                return 0
            case ModalState.Details:
                return 1
        }
    }

    const sopBuiltItemColumns: ColumnsType<SopBuiltItemOrderline> = [
        {
            key: 'Bom',
            title: 'Bom code',
            render: (sopBuiltItemOrderline: SopBuiltItemOrderline) => {
                return sopBuiltItemOrderline.builtItem.bomCode
            },
        },
        {
            key: 'Assigned',
            title: 'Assigned',
            render: (sopBuiltItemOrderline: SopBuiltItemOrderline) => {
                return `${getBuiltItemSopUsed(sopBuiltItemOrderline)}/${
                    sopBuiltItemOrderline.amount
                }`
            },
        },
        {
            key: 'Amount',
            title: 'Amount',
            render: (sopBuiltItemOrderline: SopBuiltItemOrderline) => {
                return (
                    <Input
                        prefix={'amount:'}
                        size="small"
                        style={{
                            padding: 10,
                            borderRadius: '10px',
                            width: '120px',
                        }}
                        disabled={builtItemHasBeenAdded(sopBuiltItemOrderline.id)}
                        type="number"
                        value={builtItemAmountInputs[sopBuiltItemOrderline.id]}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            updateBuiltProductInput(
                                sopBuiltItemOrderline.id,
                                itemInputHandler(
                                    e.target.value,
                                    sopBuiltItemOrderline.amount -
                                        (getBuiltItemSopUsed(sopBuiltItemOrderline) || 0)
                                )
                            )
                        }}
                    />
                )
            },
        },
        {
            key: 'Add',
            title: 'Add',
            render: (sopBuiltItemOrderline: SopBuiltItemOrderline) => {
                return (
                    <Button
                        color={'primary'}
                        disabled={
                            Number(builtItemAmountInputs[sopBuiltItemOrderline.id]) < 1 ||
                            addedBuiltItems.find((si) => si.id === sopBuiltItemOrderline.id) !==
                                undefined
                        }
                        onClick={() =>
                            pushAddedBuiltItemSop(
                                sopBuiltItemOrderline,
                                parseInt(builtItemAmountInputs[sopBuiltItemOrderline.id], 10)
                            )
                        }
                    >
                        {builtItemHasBeenAdded(sopBuiltItemOrderline.id) ? 'Added' : 'Add'}
                    </Button>
                )
            },
        },
    ]

    const sopStockItemColumns: ColumnsType<SopStockItemOrderline> = [
        {
            key: 'Code',
            title: 'Code',
            render: (sopStockItemOrderline: SopStockItemOrderline) => {
                return sopStockItemOrderline?.product?.code
            },
        },
        {
            key: 'Assigned',
            title: 'Assigned',
            render: (sopStockItemOrderline: SopStockItemOrderline) => {
                return `${getStockItemSopUsed(sopStockItemOrderline)}/${
                    sopStockItemOrderline.amount
                }`
            },
        },
        {
            key: 'Amount',
            title: 'Amount',
            render: (sopStockItemOrderline: SopStockItemOrderline) => {
                return (
                    <Input
                        prefix={'amount:'}
                        size="middle"
                        disabled={stockItemHasBeenAdded(sopStockItemOrderline.id)}
                        style={{
                            padding: 10,
                            borderRadius: '10px',
                            width: '120px',
                        }}
                        type="number"
                        value={stockItemAmountInputs[sopStockItemOrderline.id]}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            updateStockProductInput(
                                sopStockItemOrderline.id,
                                itemInputHandler(
                                    e.target.value,
                                    sopStockItemOrderline.amount -
                                        (getStockItemSopUsed(sopStockItemOrderline) || 0)
                                )
                            )
                        }}
                    />
                )
            },
        },
        {
            key: 'Add',
            title: 'Add',
            render: (sopStockItemOrderline: SopStockItemOrderline) => {
                return (
                    <Button
                        color={'primary'}
                        disabled={
                            Number(stockItemAmountInputs[sopStockItemOrderline.id]) < 1 ||
                            addedStockItems.find((si) => si.id === sopStockItemOrderline.id) !==
                                undefined
                        }
                        onClick={() =>
                            pushAddedProjectStockItem(
                                sopStockItemOrderline,
                                parseInt(stockItemAmountInputs[sopStockItemOrderline.id], 10)
                            )
                        }
                    >
                        {stockItemHasBeenAdded(sopStockItemOrderline.id) ? 'Added' : 'Add'}
                    </Button>
                )
            },
        },
    ]

    const addedItemsColumns: ColumnsType<AddedStockItemSop | AddedBuiltItemSop> = [
        {
            key: 'code',
            title: 'Code',
            render: (item: AddedStockItemSop | AddedBuiltItemSop) => {
                if (isAddedBuiltItemSop(item)) {
                    return item?.builtItem?.bomCode
                } else {
                    return item?.product?.code
                }
            },
        },
        {
            key: 'Amount',
            title: 'Amount',
            render: (item: AddedStockItemSop | AddedBuiltItemSop) => {
                return item.addedAmount
            },
        },
        {
            key: 'Remove',
            title: 'Remove',
            render: (item: AddedStockItemSop | AddedBuiltItemSop) => {
                if (isAddedBuiltItemSop(item)) {
                    return (
                        <Button color={'primary'} onClick={() => removeAddedBuiltItemSop(item.id)}>
                            Remove
                        </Button>
                    )
                } else {
                    return (
                        <Button
                            color={'primary'}
                            onClick={() => removeAddedProjectStockItem(item.id)}
                        >
                            Remove
                        </Button>
                    )
                }
            },
        },
    ]

    return (
        <Modal
            title={`Create call off for ${salesOrder.documentNo}`}
            visible={true}
            onCancel={onClose}
            onOk={createCallOff}
            width={700}
            footer={
                <>
                    {modalState === ModalState.Products && <ProductsFooter />}
                    {modalState === ModalState.Details && <DetailsFooter />}
                </>
            }
        >
            <Steps size="small" current={currentStep()} style={{ marginBottom: '40px' }}>
                <Steps.Step
                    title="Products"
                    onClick={() => setModalState(ModalState.Products)}
                    style={{ cursor: 'pointer' }}
                />
                <Steps.Step title="Details" />
            </Steps>
            {modalState === ModalState.Products && (
                <Box>
                    <ItemContainer>
                        <h3> Built items</h3>
                        <Table
                            size="small"
                            columns={sopBuiltItemColumns}
                            dataSource={builtItemOrderlines.filter((sopBuiltItemOrderline) => {
                                return (
                                    (getBuiltItemSopUsed(sopBuiltItemOrderline) || 0) <
                                    sopBuiltItemOrderline.amount
                                )
                            })}
                            pagination={false}
                        />
                    </ItemContainer>
                    <ItemContainer>
                        <h3> Stock items</h3>
                        <Table
                            size="small"
                            columns={sopStockItemColumns}
                            dataSource={stockItemOrderlines.filter(
                                (sopStockItemOrderline) =>
                                    (getStockItemSopUsed(sopStockItemOrderline) || 0) <
                                    sopStockItemOrderline.amount
                            )}
                            pagination={false}
                        />
                    </ItemContainer>
                    <AddedItemsContainer>
                        <h3> Added items</h3>
                        <Table
                            size="small"
                            columns={addedItemsColumns}
                            dataSource={[...addedBuiltItems, ...addedStockItems]}
                            pagination={false}
                        />
                    </AddedItemsContainer>
                </Box>
            )}
            {modalState === ModalState.Details && (
                <Box>
                    <InputContainer>
                        <Label text="Customer Delivery Date" />
                        <InformationButton infoText="The date the customer expects the products on site." />
                        <DatePicker
                            style={{ width: '100%' }}
                            format="DD-MM-YYYY"
                            value={formState.deliveryDate}
                            disabledDate={isPastOrWeekend}
                            onChange={(value) => updateDateFieldForm('deliveryDate', value)}
                        />
                    </InputContainer>
                    <InputContainer>
                        <Label text="Customer Dispatch Date" />
                        <InformationButton infoText="The dispatch date resembles the day on which the customer expects the items dispatched by logistics. This should be before the delivery date." />
                        <DatePicker
                            style={{ width: '100%' }}
                            format="DD-MM-YYYY"
                            value={formState.customerDispatchDate}
                            disabledDate={isPastOrWeekend}
                            onChange={(value) => updateDateFieldForm('customerDispatchDate', value)}
                        />
                    </InputContainer>
                    <InputContainer>
                        <Label text="Date to be completed by" />
                        <InformationButton infoText="The date a call off must be ready by, for dispatch to the customer." />
                        <DatePicker
                            style={{ width: '100%' }}
                            format="DD-MM-YYYY"
                            value={formState.dateToBeCompletedBy}
                            disabledDate={isPastOrWeekend}
                            onChange={(value) => updateDateFieldForm('dateToBeCompletedBy', value)}
                        />
                    </InputContainer>
                    <InputContainer>
                        <Label text="Date goods in" />
                        <InformationButton infoText="The date when goods are in." />
                        <DatePicker
                            style={{ width: '100%' }}
                            format="DD-MM-YYYY"
                            disabledDate={(current) =>
                                isPast(current, formState.dateToBeCompletedBy)
                            }
                            disabled={formState.dateToBeCompletedBy ? false : true}
                            value={formState.goodsInDate}
                            onChange={(value) => updateDateFieldForm('goodsInDate', value)}
                        />
                    </InputContainer>
                    <InputContainer>
                        <Label text="Dispatch Comment" />
                        <InformationButton infoText="This comment will be shown when dispatching the call-offs" />
                        <Input
                            size="large"
                            type="text"
                            name="name"
                            value={formState.comment}
                            onChange={(e) => updateStringFieldForm('comment', e.target.value)}
                        />
                    </InputContainer>
                    <InputContainer>
                        <Label text="Title" />
                        <InformationButton infoText="A title used to identify the call off." />
                        <Input
                            size="large"
                            type="text"
                            name="name"
                            value={formState.title}
                            onChange={(e) => updateStringFieldForm('title', e.target.value)}
                        />
                    </InputContainer>
                </Box>
            )}
        </Modal>
    )
}
