import { styled } from '@mui/system'
import { Button, Table, Input, Select } from 'antd'
import { useEffect, useState } from 'react'
import PageHeader from '../ui/PageHeader'
import { Urls } from '../../util/urls'
import {
    getCallOffsRequest,
    GetManyCallOffDateSearchType,
    GetManyCallOffSortBy,
    GetManyCallOffRelations,
} from '../../api/call-off'
import { CallOff, CallOffStatus, UserRole, WarehouseId } from '../../models/models'
import { toastFailure } from '../../util/toast'
import useDebounce from '../project-master/single-project-view/hooks'
import { useSelector } from 'react-redux'
import { RootState } from '../../store/store'
import moment from 'moment'
import UpdateCallOffModal from '../modals/update-call-off-modal/UpdateCallOff'
import InspectCallOffModal from '../modals/inspect-call-off-modal/InspectCallOffModal'
import CTRLoader from '../ui/loader/CTRloader'
import DeleteCallOffModal from '../modals/delete-call-off-modal/DeleteCallOffModal'
import DatePicker from '../ui/calendar/DatePicker'
import { callOffBuiltItemsCompletedDetails, screamCaseToRegularText } from '../../util/util'
import { FieldContainer } from '../ui/FIeldContainer'
import { Label } from '../ui/Label'
import CreateAssemblyModal from '../project-master/single-project-view/call-off/view-call-off/modals/CreateAssembly'

const Container = styled('div')`
    display: flex;
    flex-direction: column;
    gap: 15px;
    margin: 25px;
`

const FilterContainer = styled('div')`
    display: flex;
    flex-directi: row;
    flex-wrap: wrap;
    padding: 0px 0px 10px 0px;
    gap: 5px;
`

enum ActiveModal {
    None = 'None',
    Update = 'Update',
    Inspect = 'Inspect',
    Delete = 'Delete',
    CreateAssembly = 'CreateAssembly',
}

const dateSearchOptions = [
    { label: 'None', value: null },
    {
        label: 'Dispatched Date',
        value: GetManyCallOffDateSearchType.DispatchedDate,
    },
    {
        label: 'Customer Dispatch Date',
        value: GetManyCallOffDateSearchType.CustomerDispatchDate,
    },
]

const warehouseOptions = [
    { value: WarehouseId.Monument, label: 'Monument' },
    { value: WarehouseId.Scandia, label: 'Scandia' },
]

const callOffStatusOptions = [
    { value: null, label: 'Any' },
    { value: CallOffStatus.Requested, label: 'Requested' },
    { value: CallOffStatus.Approved, label: 'Approved' },
    { value: CallOffStatus.InProduction, label: 'In Production' },
    { value: CallOffStatus.Dispatched, label: 'Dispatched' },
    { value: CallOffStatus.Completed, label: 'Completed' },
]

const sortByOptions = [
    { value: null, label: 'None' },
    { value: GetManyCallOffSortBy.CallOffId, label: 'Call Off Id' },
    {
        value: GetManyCallOffSortBy.DispatchedDate,
        label: 'Dispatched Date',
    },
    {
        value: GetManyCallOffSortBy.CustomerDispatchDate,
        label: 'Customer Dispatch Date',
    },
    {
        value: GetManyCallOffSortBy.DateToBeCompletedBy,
        label: 'Date To Be Completed By',
    },
]

export default function CallOffs() {
    const [callOffs, setCallOffs] = useState<CallOff[]>([])
    const [projectName, setProjectName] = useState<string>()
    const [savNumber, setSavNumber] = useState<string | undefined>(undefined)
    const [soNumber, setSoNumber] = useState<string>('')
    const [callOffId, setCallOffId] = useState<number>()
    const [dateRangeBegin, setDateRangeBegin] = useState<Date | null>()
    const [dateRangeEnd, setDateRangeEnd] = useState<Date | null>()
    const [dateSearchType, setDateSearchType] = useState<GetManyCallOffDateSearchType | null>(
        dateSearchOptions[0].value
    )
    const [sortBy, setSortBy] = useState<GetManyCallOffSortBy | null>(sortByOptions[0].value)
    const { user } = useSelector((state: RootState) => state.authentication)
    const [selectedWarehouseId, setSelectedWarehouseId] = useState<WarehouseId>(
        user?.warehouseId || warehouseOptions[0].value
    )
    const [activeModal, setActiveModal] = useState<ActiveModal>(ActiveModal.None)
    const [modalCallOff, setModalCallOff] = useState<CallOff | undefined>(undefined)
    const [isLoading, setIsLoading] = useState<boolean | undefined>(undefined)
    const [callOffStatus, setCallOffStatus] = useState<CallOffStatus | null>(
        callOffStatusOptions[0].value
    )
    const rowsPerPageOptions = [10, 20, 50, 100]
    type RowsPerPageOptions = typeof rowsPerPageOptions[number]
    const [page, setPage] = useState<number>(1)
    const [rowsPerPage, setRowsPerPage] = useState<RowsPerPageOptions>(10)
    const [callOffsLength, setCallOffsLength] = useState<number>(0)

    const onPageChange = (page: number, pageSize: number) => {
        setPage(page)
        setRowsPerPage(pageSize)
    }

    const isCallOffSplittable = (callOff: CallOff) => {
        const details = callOffBuiltItemsCompletedDetails(callOff)

        if (Object.keys(details).length === 0) {
            return false
        }

        const values = Object.values(details)
        for (let i = 0; i < values.length; i++) {
            if (values[i].assemblyAllocatedAmount < values[i].callOffAmount) {
                return true
            }
        }

        return false
    }

    const onDateChange = (dates: [moment.Moment | null, moment.Moment | null] | null) => {
        if (dates === null) {
            setDateRangeBegin(undefined)
            setDateRangeEnd(undefined)
            return
        }

        if (dates[0] !== null) {
            setDateRangeBegin(new Date(dates[0]?.format('MM-DD-YYYY') ?? ''))
        } else {
            setDateRangeBegin(null)
        }

        if (dates[1] !== null) {
            setDateRangeEnd(new Date(dates[1]?.format('MM-DD-YYYY') ?? ''))
        } else {
            setDateRangeEnd(null)
        }
    }

    const openModal = (activeModal: ActiveModal, callOff: CallOff) => {
        setActiveModal(activeModal)
        setModalCallOff(callOff)
    }

    const closeModal = () => {
        setActiveModal(ActiveModal.None)
        setModalCallOff(undefined)
    }

    const updateCallOff = (callOff: CallOff) => {
        setCallOffs(callOffs.map((c) => (c.id === callOff.id ? callOff : c)))
    }

    const getCallOffs = async () => {
        if (isLoading === undefined) {
            setIsLoading(true)
        }
        const response = await getCallOffsRequest({
            projectName: projectName,
            projectSavNumber: savNumber,
            salesOrderNumber: soNumber,
            warehouseId: selectedWarehouseId,
            relations: [
                GetManyCallOffRelations.Assemblies,
                GetManyCallOffRelations.BuiltItemCallOff,
                GetManyCallOffRelations.StockItemCallOff,
                GetManyCallOffRelations.ProjectBuiltItem,
                GetManyCallOffRelations.ProjectStockItem,
                GetManyCallOffRelations.Bom,
                GetManyCallOffRelations.BuiltItemProduct,
                GetManyCallOffRelations.Product,
                GetManyCallOffRelations.SalesOrder,
                GetManyCallOffRelations.Project,
            ],
            status: callOffStatus ?? undefined,
            dateSearch: dateSearchType
                ? {
                      dateRangeBegin: dateRangeBegin || undefined,
                      dateRangeEnd: dateRangeEnd || undefined,
                      dateSearchType: dateSearchType,
                  }
                : undefined,
            sortBy: sortBy || undefined,
            id: callOffId || undefined,
            skip: page * rowsPerPage - rowsPerPage,
            limit: rowsPerPage,
        })

        if (response.successful) {
            setCallOffs(response.data.callOffs)
            setCallOffsLength(response.data.count ?? 0)
            setIsLoading(false)
        } else {
            toastFailure(response.message)
            setIsLoading(false)
        }
    }

    const update = useDebounce(
        JSON.stringify({
            projectName,
            callOffStatus,
            savNumber,
            soNumber,
            selectedWarehouseId,
            dateRangeBegin,
            dateRangeEnd,
            dateSearchType,
            sortBy,
            callOffId,
            rowsPerPage,
            page,
        }),
        300
    )

    useEffect(() => {
        getCallOffs()
    }, [update])

    if (isLoading && callOffs.length === 0) {
        return <CTRLoader />
    }

    const tableColumns = [
        {
            title: 'ID',
            dataIndex: 'id',
            key: 'id',
        },
        {
            title: 'Project Name',
            key: 'projectName',
            render: (callOff: CallOff) => callOff?.project?.name || 'Unknown',
        },
        {
            title: 'SO Number',
            key: 'projectName',
            render: (callOff: CallOff) => callOff?.salesOrder?.documentNo || 'Unknown',
        },
        {
            title: 'Status',
            render: (callOff: CallOff) => {
                return screamCaseToRegularText(callOff.status)
            },
            key: 'status',
        },
        {
            title: 'SAV-Number',
            key: 'opportunityReference',
            render: (callOff: CallOff) => callOff?.project?.opportunityReference || 'Unknown',
        },
        {
            title: 'Date Goods in',
            key: 'goodsInDate',
            render: (callOff: CallOff) => {
                if (callOff.goodsInDate) {
                    return moment(callOff.goodsInDate).format('DD-MM-YYYY')
                }
                return 'Not done yet'
            },
        },
        {
            title: 'Date to be Completed By',
            key: 'dateToBeCompletedBy',
            render: (callOff: CallOff) => {
                if (callOff.dateToBeCompletedBy) {
                    return moment(callOff.dateToBeCompletedBy).format('DD-MM-YYYY')
                }
                return 'Not done yet'
            },
        },
        {
            title: 'Date Requested Dispatched',
            key: 'customerDispatchDate',
            render: (callOff: CallOff) => {
                if (callOff.customerDispatchDate) {
                    return moment(callOff.customerDispatchDate).format('DD-MM-YYYY')
                }
                return 'Not done yet'
            },
        },
        {
            title: 'Dispatched Date',
            key: 'dispatchedDate',
            render: (callOff: CallOff) => {
                if (callOff.dispatchedDate) {
                    return moment(callOff.dispatchedDate).format('DD-MM-YYYY')
                }
                return 'Not done yet'
            },
        },
        {
            title: 'Dispatch Comment',
            dataIndex: 'dispatchComment',
            key: 'dispatchComment',
        },
        {
            title: 'Actions',
            key: 'actions',
            render: (callOff: CallOff) => {
                if (user !== null) {
                    return (
                        <>
                            {[CallOffStatus.InProduction, CallOffStatus.Approved].includes(
                                callOff.status
                            ) &&
                                !callOff.isOnHold &&
                                isCallOffSplittable(callOff) && (
                                    <Button
                                        style={{
                                            marginRight: '10px',
                                            marginBottom: '10px',
                                        }}
                                        onClick={() => {
                                            openModal(ActiveModal.CreateAssembly, callOff)
                                        }}
                                    >
                                        Create assembly
                                    </Button>
                                )}
                            {[
                                UserRole.Scheduler,
                                UserRole.SavAdmin,
                                UserRole.SystemAdmin,
                                UserRole.ProjectManager,
                            ].includes(user.role) &&
                                [CallOffStatus.InProduction, CallOffStatus.Approved].includes(
                                    callOff.status
                                ) && (
                                    <Button
                                        onClick={() => {
                                            openModal(ActiveModal.Update, callOff)
                                        }}
                                    >
                                        Update
                                    </Button>
                                )}

                            <Button
                                onClick={() => {
                                    openModal(ActiveModal.Inspect, callOff)
                                }}
                            >
                                Inspect
                            </Button>
                            {[
                                UserRole.Scheduler,
                                UserRole.SavAdmin,
                                UserRole.SystemAdmin,
                                UserRole.ProjectManager,
                            ].includes(user.role) &&
                                !callOff.dispatchedDate && (
                                    <Button
                                        onClick={() => {
                                            openModal(ActiveModal.Delete, callOff)
                                        }}
                                    >
                                        Delete
                                    </Button>
                                )}
                        </>
                    )
                }
            },
        },
    ]

    return (
        <>
            <PageHeader
                title="Call Offs"
                breadcrumbs={[
                    { link: Urls.Landing, name: 'Main Page' },
                    {
                        link: Urls.CallOffs,
                        name: 'Call Offs',
                    },
                ]}
            />
            <Container>
                <FilterContainer>
                    {/* Project Name */}

                    <FieldContainer>
                        <Label>Project Name</Label>
                        <Input
                            style={{ width: '100px' }}
                            placeholder="Project Name"
                            onChange={(e) => {
                                setProjectName(e.target.value)
                            }}
                        />
                    </FieldContainer>

                    {/* Sales order number */}
                    <FieldContainer>
                        <Label>SO Number</Label>
                        <Input
                            style={{ width: '100px' }}
                            placeholder="SO number"
                            onChange={(e) => {
                                setSoNumber(e.target.value)
                            }}
                        />
                    </FieldContainer>
                    {/* SAV-Number */}

                    <FieldContainer>
                        <Label>Sav Number</Label>
                        <Input
                            style={{ width: '100px' }}
                            placeholder="SAV-Number"
                            type="number"
                            onChange={(e) => {
                                if (isNaN(parseInt(e.target.value))) setSavNumber(undefined)
                                else setSavNumber(e.target.value)
                            }}
                        />
                    </FieldContainer>
                    {/* CallOff ID */}

                    <FieldContainer>
                        <Label>Call Off ID</Label>
                        <Input
                            style={{ width: '100px' }}
                            placeholder="Call Off ID"
                            type="number"
                            onChange={(e) => {
                                if (isNaN(parseInt(e.target.value))) setCallOffId(undefined)
                                else setCallOffId(parseInt(e.target.value))
                            }}
                        />
                    </FieldContainer>

                    {/* Date Range */}
                    <FieldContainer>
                        <Label>
                            {
                                dateSearchOptions.find((option) => dateSearchType === option.value)
                                    ?.label
                            }
                        </Label>
                        <DatePicker.RangePicker
                            style={{ width: '240px' }}
                            allowClear={true}
                            disabled={dateSearchType === null ? true : false}
                            value={
                                dateRangeBegin === undefined
                                    ? [null, null]
                                    : [moment(dateRangeBegin), moment(dateRangeEnd)]
                            }
                            onChange={onDateChange}
                        />
                    </FieldContainer>

                    {/* Date Search Type */}
                    <FieldContainer>
                        <Label>Date search type</Label>
                        <Select
                            style={{ width: '180px' }}
                            value={dateSearchType}
                            onChange={(value: GetManyCallOffDateSearchType | undefined) => {
                                if (!value) {
                                    setDateSearchType(null)
                                    setDateRangeBegin(undefined)
                                    setDateRangeEnd(undefined)
                                    return
                                }
                                setDateSearchType(value)
                            }}
                            options={dateSearchOptions}
                        />
                    </FieldContainer>

                    {/* Warehouse */}

                    <FieldContainer>
                        <Label>Warehouse</Label>
                        <Select
                            style={{ width: '180px' }}
                            value={selectedWarehouseId}
                            onChange={(value: WarehouseId) => setSelectedWarehouseId(value)}
                            options={warehouseOptions}
                        />
                    </FieldContainer>

                    {/* Status */}

                    <FieldContainer>
                        <Label>Status</Label>
                        <Select
                            style={{ width: '180px' }}
                            value={callOffStatus}
                            onChange={(value: CallOffStatus) => setCallOffStatus(value)}
                            options={callOffStatusOptions}
                        />
                    </FieldContainer>

                    {/* Sort By */}
                    <FieldContainer>
                        <Label>Sort by</Label>
                        <Select
                            style={{ width: '180px' }}
                            value={sortBy}
                            onChange={(value: GetManyCallOffSortBy) => setSortBy(value)}
                            options={sortByOptions}
                        />
                    </FieldContainer>
                </FilterContainer>
                <Table
                    size="small"
                    rowKey="id"
                    columns={tableColumns}
                    dataSource={callOffs}
                    pagination={{
                        defaultPageSize: 10,
                        current: page,
                        onChange: onPageChange,
                        total: callOffsLength,
                    }}
                />
            </Container>

            {modalCallOff && activeModal === ActiveModal.Update && (
                <UpdateCallOffModal
                    onUpdate={updateCallOff}
                    callOffId={modalCallOff.id}
                    onClose={() => {
                        closeModal()
                        getCallOffs()
                    }}
                />
            )}
            {modalCallOff && activeModal === ActiveModal.Inspect && (
                <InspectCallOffModal
                    callOffId={modalCallOff.id}
                    isOpen={true}
                    closeModal={() => closeModal()}
                />
            )}
            {modalCallOff && activeModal === ActiveModal.CreateAssembly && (
                <CreateAssemblyModal
                    callOffId={modalCallOff.id}
                    onClose={() => {
                        closeModal()
                        getCallOffs()
                    }}
                />
            )}
            {modalCallOff && activeModal === ActiveModal.Delete && (
                <DeleteCallOffModal
                    callOffId={modalCallOff.id}
                    isOpen={true}
                    closeModal={() => {
                        closeModal()
                        getCallOffs()
                    }}
                />
            )}
        </>
    )
}
