import { Response } from './util/with-response-formatter-interceptor'
import { CallOff, CallOffStatus } from '../models/models'
import backendConnection from './util/backend-connection'

export interface ProductIdAmount {
    productId: number
    amount: number
}

export interface AddedBuiltItemDTO {
    builtItemSopId: number
    amount: number
}

export interface AddedStockItemDTO {
    stockItemSopId: number
    amount: number
}

export interface CreateCallOffBodyDTO {
    salesOrderId: number
    addedStockItems: AddedStockItemDTO[]
    addedBuiltItems: AddedBuiltItemDTO[]
    comment: string
    customerDispatchDate: Date
    dateToBeCompletedBy: Date
    deliveryDate: Date
    dispatchComment: string
    title?: string
    goodsInDate?: Date
}

export const createCallOffRequest = (body: CreateCallOffBodyDTO): Promise<Response<number>> =>
    backendConnection.request({
        url: `call-offs`,
        method: 'POST',
        data: body,
    })

export const deleteCallOffRequest = (id: number): Promise<Response<number>> =>
    backendConnection.request({
        url: `call-offs/${id}`,
        method: 'DELETE',
    })

export const getProjectCallOffsRequest = (projectId: number): Promise<Response<CallOff[]>> =>
    backendConnection.request({
        url: `call-offs/project/${projectId}`,
        method: 'GET',
    })

export enum SingleCallOffRelations {
    Assemblies = 'Assemblies',
    AssembliesPallets = 'AssembliesPallets',
    AssembliesLines = 'AssembliesLines',
    SalesOrder = 'SalesOrder',
    SalesOrderProducts = 'SalesOrderProducts',
    SalesOrderCustomer = 'SalesOrderCustomer',
    CallOffProducts = 'CallOffProducts',
    CallOffProductsGoodsDispathced = 'CallOffProductsGoodsDispathced',
    OrderConfirmations = 'OrderConfirmations',
    CallOffProductsBomGroups = 'CallOffProductsBomGroups',
    AssembliesLinesGoodsDispatched = 'AssembliesLinesGoodsDispatched',
}

export class GetCallOffQueryParams {
    relations?: SingleCallOffRelations[]
}

export const getCallOffById = (
    callOffId: number,
    queryParams?: GetCallOffQueryParams
): Promise<Response<CallOff>> =>
    backendConnection.request({
        url: `call-offs/${callOffId}`,
        method: 'GET',
        params: queryParams,
    })

export interface ApproveCallOffDTO {
    customerDispatchDate: Date
    supplierDispatchDate?: Date
    orderIds?: number[]
    comment?: string
    dateToBeCompletedBy: Date
    dispatchComment?: string
    deliveryDate: Date
}
export const approveCallOffRequest = (
    callOffId: number,
    body: ApproveCallOffDTO
): Promise<Response<CallOff[]>> =>
    backendConnection.request({
        url: `call-offs/approve/${callOffId}`,
        method: 'POST',
        data: body,
    })

export interface ProductAmount {
    id: number
    amount: number
}

export interface AddBuiltItemCallOffDTO {
    builtItemSopId: number
    amount: number
}

export interface AddStockItemCallOffDTO {
    stockItemSopId: number
    amount: number
}

export interface DeleteBuiltItemCalloffDTO {
    builtItemCalloffId: number
}

export interface DeleteStockItemCallOffDTO {
    stockItemCallOffId: number
}

export interface UpdateCallOffDTO {
    createStockItems?: AddStockItemCallOffDTO[]
    updateStockItems?: ProductAmount[]
    deleteStockItems?: DeleteStockItemCallOffDTO[]
    createBuiltItems?: AddBuiltItemCallOffDTO[]
    updateBuiltItems?: ProductAmount[]
    deleteBuiltItems?: DeleteBuiltItemCalloffDTO[]
    customerDispatchDate?: Date
    dateToBeCompletedBy?: Date
    deliveryDate?: Date
    title?: string
    dispatchComment?: string
    goodsInDate?: Date
}

export const updateCallOff = (
    callOffId: number,
    body: UpdateCallOffDTO
): Promise<Response<CallOff>> =>
    backendConnection.request({
        url: `call-offs/${callOffId}`,
        method: 'PUT',
        data: body,
    })

export const updateOnHoldStatusForCallOff = (
    callOffId: number,
    isOnHold: boolean
): Promise<Response<CallOff>> =>
    backendConnection.request({
        url: `call-offs/on-hold-status/${callOffId}`,
        method: 'PUT',
        data: { isOnHold },
    })

export enum GetManyCallOffRelations {
    Assemblies = 'Assemblies',
    BuiltItemCallOff = 'BuiltItemCallOff',
    StockItemCallOff = 'StockItemCallOff',
    ProjectBuiltItem = 'ProjectBuiltItem',
    ProjectStockItem = 'ProjectStockitem',
    Bom = 'Bom',
    BuiltItemProduct = 'BuiltItemProduct',
    Product = 'Product',
    SalesOrder = 'SalesOrder',
    Project = 'Project',
}

export enum GetManyCallOffSortBy {
    CallOffId = 'CallOffId',
    DispatchedDate = 'DispatchedDate',
    CustomerDispatchDate = 'CustomerDispatchDate',
    DateToBeCompletedBy = 'DateToBeCompletedby',
}

export enum GetManyCallOffDateSearchType {
    DispatchedDate = 'DispatchedDate',
    CustomerDispatchDate = 'CustomerDispatchDate',
}

export interface GetManyCallOffDateSearch {
    dateRangeBegin?: Date
    dateRangeEnd?: Date
    dateSearchType: GetManyCallOffDateSearchType
}

export interface GetManyCallOffQueryParams {
    relations?: GetManyCallOffRelations[]
    dateToBeCompletedByIsBefore?: Date
    dateToBeCompletedByIsAfter?: Date
    status?: CallOffStatus
    salesOrderNumber?: string
    hasBeenDispatched?: boolean
    projectName?: string
    projectSavNumber?: string
    isCompleted?: boolean
    warehouseId?: number
    dateSearch?: GetManyCallOffDateSearch
    sortBy?: GetManyCallOffSortBy
    skip?: number
    limit?: number
    projectId?: number
    id?: number
}

export const getCallOffsRequest = (
    params: GetManyCallOffQueryParams
): Promise<Response<{ callOffs: CallOff[]; count: number }>> =>
    backendConnection.request({
        url: `call-offs`,
        method: 'GET',
        params,
    })
